
import { createRouter, createWebHistory, } from "vue-router"
import { showFailToast } from 'vant';
import { get_url_param, scrollToLocation, getIsWxClient, getOpenidStatusByP } from '@/utils/utils.js'
import store from "@/store"
import { getIsLogin, setOldUrl, getVisitorOpenid } from "@/plugins/cookie"
import { homeRoute } from "./modules/home";
import { aritcleRoute } from "./modules/article";
import { clueRoute } from "./modules/clue";
import { configRoute } from "./modules/config";
import { loginRoute } from "./modules/login";
import { messageRoute } from "./modules/message";
import { panoramaRoute } from "./modules/panorama";
import { visitorRoute } from "./modules/visitor";
import { promotionRoute } from "./modules/promotion";
import { spmRoute } from "./modules/spm";
import { outerChatRoute } from "./modules/outerChat";
import { topicRoute } from "./modules/topic";
import { chatRoute } from "./modules/chat";
const routes = [
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    children: [
      ...homeRoute,
      ...aritcleRoute,
      ...clueRoute,
      ...configRoute,
      ...loginRoute,
      ...messageRoute,
      ...panoramaRoute,
      ...visitorRoute,
      ...promotionRoute,
      ...spmRoute,
      ...topicRoute,
      ...chatRoute,
      {
        path: 'test',
        meta: {
          isShowMenu: true,
          auth: false,
          activeNav: 'home',
          isDefaultWxInit: true,
          keepAlive: true
        },
        component: () => import('@/pages/test.vue')
      },
      {
        path: 'test2',
        meta: {
          isShowMenu: true,
          auth: false,
          activeNav: 'home',
          isDefaultWxInit: true,
          keepAlive: true
        },
        component: () => import('@/pages/test2.vue')
      },
      {
        path: 'test3',
        meta: {
          isShowMenu: true,
          auth: false,
          activeNav: 'home',
          isDefaultWxInit: true,
          keepAlive: true
        },
        component: () => import('@/pages/test3.vue')
      },

      {
        path: ':error(.*)*',
        name: 'error',
        meta: {
          isShowMenu: false,
          auth: false,
          isDefaultWxInit: true,
          keepAlive: true,
          title: '404'
        },
        component: () => import('@/pages/error/index.vue')
      },

    ]
  },
  ...outerChatRoute


]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

var firstOpen = true

var AuthWhitelist = [
  '/wxAuth',
  '/login',
  '/test',
  '/recover',
]

function initAuthWhitelist() {
  var isWxClient = getIsWxClient();
  // 非微信环境 将专题页加入免授权白名单
  if (!isWxClient) {
    AuthWhitelist.push('/subjectdetails')
  }
}
initAuthWhitelist();
// 路由跳转前
router.beforeEach((to, from, next) => {
  console.log('路由跳转')
  store.commit('CHANGE_ISCHANGE_LOADING_MENU', to.meta.isShowMenu)
  store.commit('CHANGE_ACTIVE_NAV', to.meta.activeNav)
  //judgeSentPageView(window.localStorage.isLogin,to.path)
  if (firstOpen) {
    // console.log(window.location.href.split('#')[0])
    //为ios，微信jsdk授权，存储url
    store.commit('CHANGE_Wxjsdk_URL', window.location.href.split('#')[0]);
    firstOpen = false
  }
  // 只要不是登录页，就记录上一个页面地址
  if (!to.fullPath.includes('login')) {
    setOldUrl(to.fullPath)
  }
  // console.log(111);
  if (to.matched.some(m => m.meta.auth)) {
    // && (token !== null || token !== '')
    console.log('222', to.matched);
    if (getIsLogin() === '1') {
      // 二次验证登录权限
      store.dispatch('user/updatePromopterInfo').catch(e => {
        showFailToast(e.response?.data?.error?.message);
        store.commit('signOut')
      })
      next();
    } else {
      // // 只有有导航栏的页面，才记录，避免无法回到首页
      // if (to.meta.isShowMenu) {
      //   setOldUrl(to.fullPath)
      // }

      next({
        path: '/login',
        query: {
          code: get_url_param("code")
        }
      });
    }
  } else {

    // 放行白名单
    if (AuthWhitelist.some(item => item == to.path)) {
      next();
    } else {
      var promopteropenid = getVisitorOpenid();
      var promopteropenidIsValid = getOpenidStatusByP(to.query.p);
      // 如果 有openid缓存并且openid有效，则不需要进入授权页
      if (promopteropenid && promopteropenidIsValid === 'true') {
        console.log('跳转chatCover', from, to);
        if ((from.name === 'chatDetail' || !from.name) && to.name === 'chatDetail') {
          console.log('success');
          next({
            path: '/chatCover',
            query: {
              c: to.query.c,
              p: to.query.p,
              uuid: to.query.uuid,
              vshId: to.query.vshId,
              isOut:'1',
            }
          });
          return
        }
        next();
        return
      } else {
        // setOldUrl(to.fullPath)
        next();

      }
    }


  }
})

// 路由跳转后
router.afterEach((to) => {
  scrollToLocation(0, 0)
  var ua = navigator.userAgent.toLowerCase();
  // 如果需要配置默认wxjsdk，就是不能转发跟复制链接。
  if (to.meta.isDefaultWxInit) {
    store.commit('CHNAGE_WX_CONFIG', {
      title: '业之峰环保装饰', // 分享标题
      link: window.location.href.split('#')[0], // 分享链接
      desc: '诺云出品，营销神器', // 分享描述
      imgUrl: 'https://yenova-picfw.oss-cn-qingdao.aliyuncs.com/shadow/20200215084744', // 分享图标
      isHidden: true,
    })


  }
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '\u200E';
  }
  // copyText(ua)
  // alert(ua)
  // 开发者工具
  if (ua.match(/wechatdevtools/i) == "wechatdevtools") {
    store.commit('CHANGE_Wxjsdk_URL', window.location.href.split('#')[0]);
  }
  // PC端微信
  if (ua.match(/windowswechat/i) == "windowswechat") {
    store.commit('CHANGE_Wxjsdk_URL', window.location.href.split('#')[0]);
  }

})


export default router
